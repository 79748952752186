<div class="flex flex-col items-center gap-2xl md:flex-row">
  <!--left/top block-->
  <div class="mb-auto mt-0 flex flex-1 flex-col gap-y-md">
    <!--    Title & plot-->
    <div class="flex flex-col items-start items-stretch">
      <p class="plot-md">{{ knowledgeSection.plot }}</p>
      <p class="heading-2xl">{{ knowledgeSection.title }}</p>
    </div>
    <p>
      {{ knowledgeSection.text }}
    </p>
    @if (supportService.responsiblePerson$() | async; as support) {
      <cft-support-box
        class="rounded-lg border border-grey-300"
        layout="horizontal"
        bgColor="grey"
        ctaButtonsRoundingStyle="rounded-lg"
        [showTitle]="false"
        [hasShadow]="false"
        [contact]="{
          name: support.name,
          role: support.role,
          phone: support.phone,
          email: support.email
        }"
      >
        <cft-support-portrait [name]="support.name" [imageUrl]="support.portraitUrl" size="sm"></cft-support-portrait>
      </cft-support-box>
    }
    <cft-call-to-action
      [callToAction]="knowledgeSection.cta"
      callToActionStyle="button"
      class="w-fit pt-md"
      buttonStyle="secondary"
    >
    </cft-call-to-action>
  </div>

  <!--right/bottom block-->
  <div class="flex-1">
    <bgo-rich-accordion [accordion]="knowledgeSection.faq"></bgo-rich-accordion>
  </div>
</div>
