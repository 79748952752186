import {KnowledgeSection} from '../types/knowledge-section.type'
import {parseCallToAction} from './call-to-action.serde'
import {KnowledgeSectionFieldsFragment} from '../../../../__generated__/datocms.types'
import {validateDatoCmsTypes} from './common.serde'
import {parseAccordion} from './accordion.serde'

export function parseKnowledgeSection(k: KnowledgeSectionFieldsFragment): KnowledgeSection {
  const entry = validateDatoCmsTypes(k)

  return {
    __type: 'KnowledgeSection',
    title: entry.title!,
    plot: entry.plot!,
    text: entry.text!,
    cta: parseCallToAction(entry.cta!),
    faq: parseAccordion(entry.faq!),
  }
}
